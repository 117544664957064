<template>
    <div class="main">
        <Title><pre  style="font-family:initial;">专注价值  广受认可</pre></Title>
        <!-- <Pgp> 在十多年的发展历程中，历思旗下的3家鉴定所始终坚持技术立所，专注于客户价值提供，以打造“百年老店”为追求，逐步赢得口碑，树起品牌。</Pgp> -->
        <div class="box">
            <div class="item hvr-forward">
                <div class="title">
                    交通案件
                </div>
                <div class="content">
                   历思长期接收交管部门委托的酒精检测任务，多年来始终坚持原则、诚信立业，深受交管部门信任；长期接收重大交通事故案件相关鉴定工作，包括酒驾毒驾鉴定、DNA鉴定 、 痕迹鉴定、微量物证鉴定、录音鉴定、图像鉴定、法医临床鉴定、法医病理鉴定等。 
                </div>
            </div>
            <div class="item hvr-forward">
                <div class="title">
                    涉毒案件
                </div>
                <div class="content">
                   历思服务于多个省份的禁毒工作。在2017年厦门金砖国家峰会期间，历思负责了安保相关的毒品、毒物、吸毒人员鉴定工作，为金砖会议提供了优质及时的服务。历思长期为厦门海关缉私提供一站式综合物证服务，2018年配合厦门海关出色完成 1.16吨毒品走私大案相关取证、鉴定工作
                </div>
            </div>
            <div class="item hvr-forward">
                <div class="title">
                    环境案件
                </div>
                <div class="content">
                   历思多年来承担了福建省大量环境案件鉴定工作，为生态环境、自然资源、公安、海警、渔政、市场监督等部门办理环境案件提供了坚实支撑，为国家环境损害赔偿制度改革与环境法治工作推进做好服务。
                </div>
            </div>
            <div class="item hvr-forward">
                <div class="title">
                    入选典型
                </div>
                <div class="content">
                   2016年，历思承办的文书鉴定案件入选福建省高级人民法院公布的福建省2015年十大典型案件，为该案提供了关键证据。
                    
                </div>
                <div class="content">
                   2018年，历思多篇法医类案例被中国法律服务网评选为典型案例收录在案例库中。
                    
                </div>
            </div>
            <div class="item hvr-forward">
                <div class="title">
                    承担地标
                </div>
                <div class="content">
                   2017年12月，历思承担的《环境损害鉴定评估通用规范》福建省地方标准正式发布，该标准是国内该领域首个标准。


                </div>
                <div class="content">
                   2021年9月，历思受福建省禁毒委员会办公室委托承担的《生物检材中毒品及其代谢物分析操作规程》福建省地方标准正式发布。


                </div>
            </div>
            <div class="item hvr-forward">
                <div class="title">
                    国家试点
                </div>
                <div class="content">
                   2018年1月，环保部正式批准历思联合漳州开发区申报的《环境证据化规范研究与应用试点》项目。该试点为目前福建省唯一的国家级环境损害鉴定评估技术试点，也是目前国内首个研究环境执法与司法衔接的环保部试点
                </div>
            </div>
        </div>
        <Pgp :indent="'2rem'">
            在十多年的发展历程中，历思旗下的3家鉴定所始终坚持技术立所，专注于客户价值提供，以打造“百年老店”为追求，逐步赢得口碑，树起品牌。
        </Pgp>
        <Pgp :indent="'2rem'">
            福建历思司法鉴定所是国内禁毒领域领先的司法鉴定机构，在血液、尿液、毛发、成品、原料、环境的毒检方面均有深入研究；近年来承担了福建省大量涉毒案件鉴定工作，与省内各地市警方就制贩毒、查吸、在册人员管控、毒情监测分析、毒情逆向追踪等展开系统性合作，并长期接收来自海南、江苏、湖南、湖北、河北、河南、浙江、江西、山东、山西、广东、广西等全国各地的涉毒案件。
        </Pgp>
        <Pgp :indent="'2rem'">
            福州堃晟检测技术有限公司司法鉴定所在先进仪器及前沿技术支撑下，可高速有效地做好毒品检测鉴定工作。为配合公安部门工作，机构人员随时待命，以实现对禁毒工作的快速响应、高效协助。
        </Pgp>
        <Pgp :indent="'2rem'">
            福建历思司法鉴定所泉州分所是泉州市最早成立的文书、痕迹司法鉴定机构，在文书形成时间检验及篡改（污损）文件检验等方面优势突出。十多年来承担了泉州地区文痕鉴定的主要工作。
        </Pgp>
    </div>
</template>
<script>
export default {
    data(){
        return {
        }
    }
}
</script>
<style scoped>
.main{
    width:80vw;
    max-width:1600px;
    margin:0 auto;
}
.title{
    margin:3rem 0;
    font-size:1.3rem;
    
}
.box{
    margin:3rem 0;
    display:flex;
    flex-wrap:wrap;
    justify-content:space-around;
}
.item{
    width:209px;
    padding: 0 20px ;
    letter-spacing:2px;
    background-color:#21ade5;
    color:#fff;
    height:30rem;
    margin-top:20px;
}
.content{
    font-size:1rem;
    text-indent:2rem;
    text-align:left;
}
</style>